import ReconnectingWebSocket from 'reconnecting-websocket';

let rws = null;
let globalCallback = null;

const createWebSocket = (url) => {
  rws = new ReconnectingWebSocket(url);
  rws.addEventListener('open', () => {
    if (!rws) {
      return;
    }

    if (rws.readyState === 1) {
      webSocketOpen();
    }
  });

  rws.addEventListener('message', (e) => {
    webSocketOnMessage(e.data);
  });

  rws.addEventListener('error', (e) => {
    console.error(e);
  });

  rws.addEventListener('close', () => {
    webSocketClose();
  });
};

const webSocketOpen = () => {
  console.log('ws open');
  sendWebSocket({}, () => {});
};

const webSocketSend = (data) => {
  rws.send(data);
};

const webSocketOnMessage = (data) => {
  globalCallback(data);
};

const webSocketClose = () => {
  rws.close();
};

const sendWebSocket = (data, callback) => {
  globalCallback = callback;
  switch (rws.readyState) {
    case rws.OPEN:
      console.log('OPEN');
      webSocketSend(data);
      break;
    case rws.CONNECTING:
      console.log('CONNECTING');
      setTimeout(() => {
        webSocketSend(data, callback);
      }, 1000);
      break;
    case rws.CLOSING:
      console.log('CLOSING');
      setTimeout(() => {
        webSocketSend(data, callback);
      });
      break;
    case rws.CLOSED:
      console.log('CLOSED');
      break;
    default:
      break;
  }
};

export { createWebSocket, webSocketClose, sendWebSocket };
